html,
body {
  width: 99%;
  height: 100%;
}

body {
  position: relative;
}

.container-fluid {
  padding-right: 0%;
  padding-left: 0%;
}


/*:::::::HEADER::::::::::::*/
#menu {
  background-color: white;
  padding-left: 0px;
  padding-top: 0px;
  text-align: start;
}

.navbar-nav li a {
  color: #444454;
}

.navbar-nav li a.active {
  border: 2px solid #444454;
  padding: 10px;
  border-radius: 25px;
  width: 110%;

}

.navbar-nav {
  width: 100%;
  padding: 5px;
  position: fixed;
  top: 0px;
  text-align: center;
  transition: .5s;
  z-index: 999;
}

.navbar-nav li {
  padding: 1% 3%;
  list-style-type: none;
  display: inline-block;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 25px;
  transition: .5s;
}

.navbar li a {
  color: rgba(0, 0, 0, .7) ! important;
}

.navbar li a.active {
  background-color: white ! important;
}


#Inicio {
  background: url(../public/images/inicio.png);
  height: 100vh;
  color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  text-align: right;
  justify-content: center;
}

#Logo-inicio {
  text-align: right;
  padding-top: 15%;
  padding-right: 10%;
  box-sizing: border-box;
}

#Logo-inicio>img {
  width: 100%;
  max-width: 300px;
}

#Mas-info {
  text-align: right;
  padding-top: 2%;
  padding-right: 7%;
  box-sizing: border-box;
}

#Mas-info>img {
  width: 100%;
  max-width: 120px;
}

#imagen-logo {
  width: 35%;
  padding-bottom: 0px;
  padding-top: 10px;
  margin-left: 0px;
  box-sizing: border-box;
}

/*:::::::NOSOTROS::::::::::::*/
#Nosotros {
  background: url(../public/images/nosotros.png);
  height: 100vh;
  color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  text-align: right;
  justify-content: center;
  padding-top: 8%;
  padding-left: 10%;
}

#Titulo-Nosotros {
  text-align: start;
  padding-left: 5%;
  word-wrap: break-word;
}

#info-Nosotros {
  text-align: start;
  padding-left: 10%;
  padding-top: 5%;
}


/*:::::::::SERVICIOS::::::::::::::*/
#Servicios,
#Contacto {
  height: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
}

#Servicios {
  padding-top: 5%;
  padding-left: 15%;
  padding-right: 5%;
}

#Titulo-Servicios {
  width: 100%;
}

.Tipo-ServicioC {
  width: 100%;
  display: flex;
}

#Imagen-Servicio {
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

#Info-Servicio {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.Servicio-Img-Izq {
  text-align: end;
}

.Servicio-Text-Izq {
  text-align: end;
}

/*:::::::::::::ALIADOS::::::::::::::::::::*/
#Aliados {
  background: url(../public/images/aliados.png);
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: left;
  background-size: 70% 100%;
  flex-wrap: wrap;
}

#Info-Aliados {
  padding-top: 18%;
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
  flex-direction: column;
  box-sizing: border-box;
}

#Titulo-Aliados {
  padding-top: 23%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

#Carousel-Aliados {
  height: 450px;
}

#Vision-BG {
  background: url(../public/images/vision.png);
  width: 100%;
  min-height: 370px;
  color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  text-align: right;
  justify-content: center;
  background-size: 100% 100%;
}

#Mision-BG {
  background: url(../public/images/mision.png);
  width: 100%;
  min-height: 370px;
  color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  text-align: right;
  justify-content: center;
  background-size: 100% 100%;
}

/*:::::::::::::::::::CONTACTO:::::::::::::::::*/
#Contacto {
  width: 90%;
  margin: 0 0 0 10%;
}

/*#Titulo-Contacto{
  width: 100%;
}
#Info-Contacto{
  width: 100%;
}*/
#Map-Dass {
  width: 100%;
}

.contacto .formulario {
  padding-top: 10%;
}

.contacto .formulario input[type="text"],
.contacto .formulario input[type="email"],
.contacto .formulario input[type="tel"],
.contacto .formulario input[type="asunto"],
.contacto .formulario textarea {
  border: 0;
  border-bottom: 2px solid #cecece;
  font-size: 15px;
  color: #3B3C3D;
}

.contacto .formulario input[type="text"],
.contacto .formulario input[type="tel"] {
  width: 90%;
  margin-bottom: 15px;
}

.contacto .formulario input[type="email"] {
  width: 90%;
  margin-bottom: 15px;
}

.contacto .formulario textarea {
  width: 90%;
  height: 100%;
  min-width: 90%;
  max-width: 90%;
  min-height: 100px;
  max-height: 100px;
  margin-bottom: 0px;
}

#Boton-Enviar {
  width: 100%;
  max-width: 150px;
  margin-left: 30%;
}

#map {
  width: 95%;
  height: 65%;
}

#Map-Dass {
  width: 100%;
  height: auto;

}

/*:::::::::::::::TEXTOS::::::::::::::::::::::::*/

#Nosotros span {
  font-family: Rubik-Black;
}


#Titulo-Mision,
#Titulo-Vision,
#Titulo-Nosotros {
  font-family: Rubik-Black;
  font-size: 8vh;
  color: white;
  text-align: start;
}

#Titulo-Servicios,
#Contacto,
#Titulo-Aliados {
  font-family: Rubik-Black;
  font-size: 8vh;
  color: black;
  text-align: start;
}

#Titulo-Contacto {
  padding-left: 10%;
}

#info-Nosotros,
#Info-Mision,
#Info-Vision p {
  font-family: Rubik-Regular;
  color: white;
  font-size: 2.5vh;
  text-align: start;
}

#Puntos-Vision li {
  font-family: Rubik-Regular;
  font-size: 2.0vh;
  color: white;
  text-align: start;
}


#Info-Servicio p {
  font-family: Rubik-Regular;
  color: black;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 20px;
}

#Info-Servicio h3 {
  font-family: Rubik-Black;
  font-size: 20px;
}

#Info-Servicio p {
  font-family: Rubik-Medium;
  font-size: 20px;
}

/*::::::::MEDIA QUERIES*/
@media screen and (max-width:800px) {
  #Nosotros {
    width: auto;
    height: auto;
  }

  #Logo-inicio {
    padding-top: 20%;
    padding-left: 30%;
  }

  #Titulo-Nosotros,
  #Titulo-Servicios,
  #Titulo-Contacto hi {
    font-size: 7vh;
  }


  /*BLOQUE ACTUALIZADO*/

  #Info-Mision {
    width: 60%;
  }

  #Info-Vision {
    padding-left: 5%;
    width: 100%;
  }

  #Puntos-Vision {
    padding-left: 0%;
    width: 70%;
  }

  /*BLOQUE ACTUALIZADO*/

  #Servicios {
    height: auto;
  }

  #Aliados {
    background-size: auto auto;
  }

  #Titulo-Aliados hi {
    font-size: 7vh;
    color: white;
  }

  #Titulo-Aliados {
    padding-left: 12vh;
  }

  #Info-Aliados {
    padding-top: 30%;
    display: flex;
    justify-content: center;
    text-align: -webkit-center;
    flex-direction: column;
    box-sizing: border-box;
  }

  #Carousel-Aliados {
    height: 200px;
  }

  #Map-Dass {
    width: 100%;
    height: 500px;
  }

  #Contacto {
    padding-left: 0%;
  }

  #logo-Dass {
    width: 85%;

  }

  #menu-boton {
    width: 15%;
  }

  .navbar-collapse {
    background-color: white;
  }
}

@media screen and (max-width:500px) and (orientation:portrait) {

  #Nosotros,
  #Servicios {
    padding-top: 20%;
    padding-bottom: 20%;
  }

  #Titulo-Nosotros {
    padding-left: 10%;
  }

  #Servicios {
    padding-left: 5vh;
  }

  .Tipo-ServicioC {
    padding-bottom: 20%;
  }

  #Vision-BG {
    height: 110vh;
    width: 100%;
    background-position: left;
    background-size: auto;
  }

  #Mision-BG {
    height: 110vh;
    width: 100%;
    background-position: left;
    background-size: auto;
  }

}

@media screen and (max-width:400px) and (orientation:portrait) {
  #Logo-inicio {
    padding-top: 60%;
    padding-left: 30%;
  }

  #Nosotros {
    width: auto;
    height: auto;
    padding-left: 0%;
  }

  #Titulo-Nosotros hi {
    font-size: 6vh;
  }
}

@media screen and (min-width:400px) and (max-width:900px) and (orientation:landscape) {
  #Logo-inicio {
    padding-top: 20%;
    padding-left: 40%;
  }

  #Nosotros {
    width: auto;
    height: 100%;
    padding-left: 0%;
  }

  #Titulo-Nosotros hi {
    font-size: 5vw;
  }

  #info-Nosotros p {
    font-size: 2vw;
  }

  /*BLOQUE ACTUALIZACION*/


  #Info-Mision {
    width: 60%;
  }

  #Info-Vision,
  #Puntos-Vision {
    width: 60%;
  }

  #Puntos-Vision {
    font-size: 1.5vw;
  }

  /*FIN BLOQUE ACTUALIZACION*/

  #Aliados {
    width: auto;
    height: auto;
    padding-left: 0%;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  #Titulo-Aliados,
  #Info-Aliados {
    padding-top: 5%;
  }

  #Titulo-Aliados hi {
    color: white;
  }

  #imagen-logo {
    width: 150px;
  }

  .carousel-inner img {
    height: 80vh;
  }


}

/*for example ipad-pro*/
@media screen and (min-width:1000px) and (orientation:portrait) {


  #Aliados {
    width: 100%;
    height: 50%;
    padding-left: 0%;
  }

  #Servicios {
    width: 100%;
    height: auto;
    padding-bottom: 10%;
  }

  #Contacto {
    font-size: 7vw;
  }

  #Contacto {
    width: 100%;
    height: 50%;
  }

  .carousel-inner img {
    height: 60vh;
  }

  #Vision-BG {
    height: 80vh;
    width: 100%;
    background-position: left;
  }

  #Mision-BG {
    height: 80vh;
    width: 100%;
    background-position: left;
  }
}



@media screen and (max-width:1500px) and (orientation:landscape) {
  .carousel-inner img {
    height: 60vh;
  }

  #Vision-BG {
    height: 70vh;
    width: 100%;
  }

  #Mision-BG {
    height: 70vh;
    width: 100%;
  }
}

/*IPAD*/
@media only screen and (device-width : 768px) and (device-height : 1024px) and (orientation:portrait) {

  #Nosotros,
  #Servicios {
    padding-top: 10%;
    padding-bottom: 10%;
  }

  #Vision-BG {
    height: 110vh;
    width: 100%;
    background-position: left;
  }

  #Mision-BG {
    height: 110vh;
    width: 100%;
    background-position: left;
  }
}

@media only screen and (device-width : 1024px) and (device-height : 768px) and (orientation:landscape) {

  .carousel-inner img {
    height: 60vh;
  }

  #Vision-BG {
    height: 70vh;
    width: 100%;
  }

  #Mision-BG {
    height: 70vh;
    width: 100%;
  }
}

/*Iphone X*/
@media only screen and (device-width : 812px) and (device-height : 375px)and (-webkit-device-pixel-ratio : 3) and (orientation:landscape) {

  #Servicios {
    height: auto;
  }

  #Aliados {
    background-size: 200% 100%;
  }

}

@media only screen and (device-width : 823px) and (device-height : 411px) and (orientation:landscape) {
  #Servicios {
    height: auto;
  }

  #Aliados {
    background-size: cover;
  }
}